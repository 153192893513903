import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import ShimmerButton from "./ShimmerButton";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleJoinBetaClick = (e) => {
    e.preventDefault();
    navigate("/", { replace: true });
    setTimeout(() => {
      const contactForm = document.getElementById("contact-form");
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
    closeMenu();
  };

  return (
    <header className="relative z-50 bg-slate-500 text-black font-bold font-poppins shadow-lg top-0 mx-8 my-4 rounded-full">
      <div className="container mx-auto flex justify-between items-center py-4 px-5 max-w-screen-xl">
        <div className="flex items-center font-poppins font-bold text-black text-3xl">
          <Link to="/" className="flex items-center">
            <img
              src="/images/mindrllogo.png"
              alt="Mindlr Logo"
              className="h-8 w-auto"
            />{" "}
            Mindlr
          </Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-2xl">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <nav
          className={`${
            isOpen ? "flex" : "hidden"
          } md:flex md:items-center absolute md:relative top-0 left-0 w-full md:w-auto bg-transparent md:bg-transparent p-5 md:p-0 z-50`}
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <div className="flex flex-col md:flex-row md:items-center w-full">
            <Link
              to="/sign-in"
              className="block md:inline-block mt-2 md:mt-0 hover:underline md:mr-4"
              onClick={closeMenu}
            >
              Sign In
            </Link>
            <Link
              to="/blog"
              className="block md:inline-block mt-2 md:mt-0 hover:underline md:mr-4"
              onClick={closeMenu}
            >
              Blog
            </Link>
          </div>
          <div className="md:ml-4">
            <a
              href="#contact-form"
              className="block md:inline-block mt-2 md:mt-0"
              onClick={handleJoinBetaClick}
            >
              <ShimmerButton className="text-sm md:text-base">
                Join beta
              </ShimmerButton>
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
