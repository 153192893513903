import React, { useEffect, useRef, useState } from "react";
import InstagramAuth from "../components/InstagramAuth";
import RedirectHandler from "../components/RedirectHandler";
import VideoCard from "../components/VideoCard";
import VideoUploadPopup from "../components/VideoUploadPopup";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaPlus, FaPen, FaCheck, FaTrash } from "react-icons/fa";
import NotificationPopup from "../components/NotificationPopup"; // Ensure you import the NotificationPopup component
import FeedbackForm from "../components/FeedbackForm";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("namekit");
  const [showRedirectHandler, setShowRedirectHandler] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [videos, setVideos] = useState([]);
  const [aiTools, setAiTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [comments, setComments] = useState({}); // State for individual comments
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [newName, setNewName] = useState("");
  const [newBio, setNewBio] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0); // State for notification count
  const [showNotificationPopup, setShowNotificationPopup] = useState(false); // State for showing notification popup
  const [activeSection, setActiveSection] = useState(null); // Tracks the active section: "followers" or "following"
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const profilePicInputRef = useRef(null);
  const navigate = useNavigate();
  const fetchNotificationCount = async (userId) => {
    try {
      const response = await fetch(
        "https://themindlr.com/api/notifications/count",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNotificationCount(data.unreadCount);
        console.log(notificationCount);
      } else {
        console.error("Failed to fetch notification count");
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };
  const fetchUserInfo = async () => {
    try {
      const response = await fetch("https://themindlr.com/api/user", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        setNewName(userData.name || "");
        setNewBio(userData.bio || "");
        console.log("Fetched user data:", userData);
        fetchUserVideos(userData._id);
        fetchNotificationCount(userData._id);
        setLoading(false); // Set loading to false after data is fetched
      } else {
        console.error("Failed to fetch user info");
        setError("Failed to fetch user info");
        setLoading(false); // Set loading to false in case of error
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      setError("Error fetching user info");
      setLoading(false);
    }
  };

  // Call the fetchUserInfo function only once when the component mounts
  useEffect(() => {
    fetchUserInfo(); // Fetch the user data when the component loads

    // Optional cleanup function (if needed to cancel requests or handle unmounting)
    return () => {
      console.log("Cleaning up user profile fetch");
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Use location.search to get current URL query
    const code = queryParams.get("code");
    const caseParam = queryParams.get("case"); // Fetch the 'case' query param

    if (code) {
      // If there is a code, switch to the "posts" tab and show the RedirectHandler
      setActiveTab("posts");
      setShowRedirectHandler(true);
    } else if (caseParam === "posts") {
      // If 'case=posts', activate the "posts" tab
      setActiveTab("posts");
    } else {
      setActiveTab("namekit"); // Default to namekit
    }
  }, [location.search]);

  const handleNotificationClick = async () => {
    try {
      // Mark notifications as read
      const response = await fetch(
        "https://themindlr.com/api/notifications/mark-read",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: user._id }),
        }
      );

      if (response.ok) {
        setNotificationCount(0); // Reset notification count to 0
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }

    setShowNotificationPopup((prev) => !prev); // Toggle the notification popup
  };

  const handleProfilePicUpload = async (e) => {
    const formData = new FormData();
    formData.append("profilePic", e.target.files[0]);

    try {
      const response = await fetch(
        "https://themindlr.com/api/user/profile-pic",
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        setUser(updatedUser);
        console.log("Updated User:", updatedUser);
        window.location.reload();
      } else {
        console.error("Failed to upload profile picture");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const fetchUserVideos = async (userId) => {
    try {
      const response = await fetch(
        `https://themindlr.com/api/videos?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.ok) {
        const videoData = await response.json();
        setVideos(videoData);
      } else {
        console.error("Failed to fetch videos:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const handleToolSelect = async (toolId) => {
    try {
      console.log("Adding Tool:", toolId);

      const response = await fetch("https://themindlr.com/api/user/tools/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ tools: [toolId] }),
      });

      if (response.ok) {
        //alert("Tool added successfully!");
        setShowDropdown(false);
        window.location.reload();
      } else {
        console.error("Failed to add tool:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding tool:", error);
      alert("An error occurred while adding the tool.");
    }
  };

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setSearching(true);

    if (term.trim() === "") {
      setSearchResults([]);
      setSearching(false);
      return;
    }

    try {
      const response = await fetch(
        `https://themindlr.com/api/ai-tools/search?term=${term}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        const results = await response.json();
        setSearchResults(results);
      } else {
        console.error("Failed to search AI tools");
      }
    } catch (error) {
      console.error("Error searching AI tools:", error);
    } finally {
      setSearching(false);
    }
  };

  const handleCommentChange = (e, toolId) => {
    const newComment = e.target.value;

    if (!toolId) {
      console.error("Tool ID is undefined or null in handleCommentChange");
      return;
    }

    console.log(
      `Changing comment for Tool ID: ${toolId}, New Comment: ${newComment}`
    );

    // Update the user toolbox directly
    setUser((prevUser) => {
      const updatedToolbox = prevUser.toolbox.map((item) => {
        if (item._id.toString() === toolId) {
          return { ...item, comment: newComment };
        }
        return item;
      });

      return { ...prevUser, toolbox: updatedToolbox };
    });

    setComments((prevComments) => ({
      ...prevComments,
      [toolId]: newComment,
    }));
  };

  const handleCommentSubmit = async (toolId) => {
    console.log("Attempting to submit comment for Tool ID:", toolId);

    // Call the handleSaveAllComments function to save all comments
    handleSaveAllComments();
  };

  const saveComment = async (toolId, comment) => {
    try {
      const response = await fetch(
        "https://themindlr.com/api/user/tools/comments",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ toolId, comment }),
        }
      );

      if (!response.ok) {
        console.error("Failed to save comment:", response.statusText);
      } else {
        console.log("Comment saved successfully for tool ID", toolId);
      }
    } catch (error) {
      console.error("Error saving comment:", error);
    }
  };

  const handleUploadComplete = () => {
    setShowRedirectHandler(false);
    if (user) fetchUserVideos(user._id);
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };
  const toggleSection = (section) => {
    if (activeSection === section) {
      // Close the section if it's already open
      setActiveSection(null);
      setUserList([]);
    } else {
      // Open the clicked section
      setActiveSection(section);
      setUserList(section === "followers" ? user.followers : user.following);
    }
  };
  const navigateToUploaderProfile = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`); // Example route, adjust to your routing
  };

  const handleLogout = async () => {
    try {
      const response = await fetch("https://themindlr.com/api/logout", {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        navigate("/sign-in");
      } else {
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch("https://themindlr.com/api/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // This is important to send the cookies with the request
        body: JSON.stringify({
          userId: user._id, // Send the user ID in the request body
          currentPassword,
          newPassword,
        }),
      });

      if (response.ok) {
        alert("Password updated successfully");
        setShowResetPasswordPopup(false);
      } else {
        // Try to parse the response as JSON
        try {
          const data = await response.json();
          alert(`Failed to update password: ${data.message}`);
        } catch (jsonError) {
          // If parsing fails, display a general error message
          alert("Failed to update password: An error occurred.");
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://themindlr.com/api/user/update/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ name: newName, bio: newBio }),
        }
      );

      if (response.ok) {
        alert("Profile updated successfully!");
        setShowEditPopup(false);
        window.location.reload(); // Refresh the page to show updated info
      } else {
        console.error("Failed to update profile:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("An error occurred while updating the profile.");
    }
  };

  const handleSaveAllComments = async () => {
    try {
      const response = await fetch(
        "https://themindlr.com/api/user/tools/comments",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ comments }),
        }
      );

      if (!response.ok) {
        console.error("Failed to save comments:", response.statusText);
        alert("Failed to save comments. Please try again.");
      } else {
        const updatedUser = await response.json();
        console.log("Comments saved successfully");
        setUser(updatedUser); // Update user state with new data
        setComments({}); // Clear the comments state
        alert("Comments saved successfully!");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error saving comments:", error);
    }
  };

  const handleToolDelete = async (toolId) => {
    const confirmed = window.confirm("Delete Tool?");
    if (!confirmed) return;

    try {
      const response = await fetch(
        `https://themindlr.com/api/users/${user._id}/toolbox/${toolId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (response.ok) {
        setUser((prevUser) => ({
          ...prevUser,
          toolbox: prevUser.toolbox.filter(
            (toolItem) => toolItem._id.toString() !== toolId
          ),
        }));
        //alert("Tool deleted successfully");
      } else {
        console.error("Failed to delete tool:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting tool:", error);
    }
  };
  const [textareaHeights, setTextareaHeights] = useState({});
  const textareaRefs = useRef({}); // Ref to hold references to all textareas

  // Function to handle dynamic resizing of textareas
  const handleResize = (toolId, event) => {
    const textarea = event.target;
    textarea.style.height = "auto"; // Reset height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to fit content

    // Store the height in state
    setTextareaHeights((prevHeights) => ({
      ...prevHeights,
      [toolId]: textarea.scrollHeight,
    }));
  };

  // Ensure the textareas are resized correctly when the component mounts or the content updates
  useEffect(() => {
    Object.keys(textareaRefs.current).forEach((toolId) => {
      const textarea = textareaRefs.current[toolId];
      if (textarea) {
        textarea.style.height = "auto"; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content
      }
    });
  }, [comments]); // Re-run the effect whenever the `comments` state changes

  const renderContent = () => {
    switch (activeTab) {
      case "posts":
        return (
          <div>
            <div className="md:ml-6 w-screen md:w-auto flex justify-center items-center">
              <div className="flex items-center space-x-0 mr-40 mt-4 md:mt-4 md:mr-0 ml-4 md:ml-10">
                <InstagramAuth onClick={() => setShowRedirectHandler(true)} />
                {showRedirectHandler && (
                  <RedirectHandler onUploadComplete={handleUploadComplete} />
                )}
                <button
                  onClick={() => setShowUploadPopup(true)}
                  className="text-black font-poppins bg-slate-500 rounded-full p-4"
                >
                  <FaPlus className="text-2xl" />
                </button>
                {showUploadPopup && (
                  <VideoUploadPopup
                    onClose={() => setShowUploadPopup(false)}
                    onUploadComplete={handleUploadComplete}
                    userId={user._id}
                  />
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-1 mt-8">
              {videos.map((video) => (
                <div id={video._id} key={video._id}>
                  {" "}
                  {/* Add the ID here */}
                  <VideoCard video={video} className="h-36" />
                </div>
              ))}
            </div>
          </div>
        );

      case "namekit":
        return (
          <div className="ml-4 md:ml-6 mb-20 md:md-0">
            <h2 className="text-lg font-bold">
              {user?.name || "User"}'s Toolkit
            </h2>
            <div className="space-y-8">
              {user?.toolbox && user.toolbox.length > 0 ? (
                user.toolbox.map((toolItem, index) => {
                  const toolId = toolItem._id.toString();

                  return (
                    <React.Fragment key={toolId}>
                      <div className="flex items-center space-x-2 w-full md:w-auto">
                        <div className="flex flex-col items-center w-20 md:w-48 space-y-2">
                          <img
                            src={toolItem.tool.Image} // Directly using the image URL from MongoDB
                            alt={toolItem.tool.Name}
                            className="w-12 h-12 mt-6 rounded-full"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/images/defaultprofpic1.jpg";
                            }}
                          />
                          <span className="mt-2 text-black">
                            {toolItem.tool.Name}
                          </span>
                        </div>

                        <div className="relative flex w-full">
                          <textarea
                            value={
                              comments[toolId] !== undefined
                                ? comments[toolId]
                                : toolItem.comment || ""
                            }
                            onChange={(e) => handleCommentChange(e, toolId)}
                            placeholder={`Why ${toolItem.tool.Name}?`}
                            className="text-black font-open-sans border-none p-2 md:p-4 border bg-gray-300 rounded-lg md:rounded-full ml-0 md:ml-4 w-full pr-20 text-start  "
                            style={{
                              wordBreak: "break-word", // Ensures long words are wrapped correctly
                              lineHeight: "1.2", // Line height for spacing
                              boxSizing: "border-box", // Ensures padding is part of width
                              maxWidth: "100%", // Ensures textarea doesn't overflow its container
                              minWidth: "0", // Prevents shrinking too much
                              padding: "20px", // Padding added for better text spacing
                              paddingRight: "62px", // Space for the FaCheck icon
                              textAlign: "justify", // Even text distribution
                              resize: "none", // Disables manual resizing
                              height: "auto", // Adjusts height automatically
                            }}
                          />
                          {comments[toolId] && (
                            <FaCheck
                              className="absolute top-1/2 text-black transform -translate-y-1/2 right-4 cursor-pointer"
                              onClick={() => handleCommentSubmit(toolId)}
                            />
                          )}
                        </div>
                        <style jsx>{`
                          @media (max-width: 768px) {
                            textarea {
                              min-height: 150px; // Larger default height for mobile screens
                              padding: 30px; // Adds more padding for mobile view
                              padding-right: 60px; // Maintain space for FaCheck on mobile too
                            }
                          }
                        `}</style>

                        {/* Delete Tool Icon */}
                        <FaTrash
                          className="text-red-500 cursor-pointer ml-2 text-lg"
                          style={{ marginRight: "8px" }}
                          onClick={() => setShowDeletePopup(toolId)}
                        />
                      </div>
                      <hr className="my-2 md:ml-56 ml-24 mr-2 border-gray-300" />

                      {/* Delete Confirmation Popup */}
                      {showDeletePopup === toolId && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                            <h2 className="text-xl font-bold text-black mb-4">
                              Are you sure?
                            </h2>
                            <div className="flex justify-around">
                              <button
                                onClick={() => setShowDeletePopup(null)}
                                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                              >
                                No
                              </button>
                              <button
                                onClick={() => handleToolDelete(toolId)}
                                className="bg-red-500 text-black px-4 py-2 rounded-full"
                              >
                                Yes, Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <p>No tools found in the user's toolkit.</p>
              )}
            </div>
            {/* Add Tool Button */}
            <div className="flex justify-start ml-4 mt-4">
              <div className="relative mr-4">
                <button
                  onClick={() => setShowDropdown((prev) => !prev)}
                  className="bg-gray-500 text-white p-4 rounded-full"
                >
                  <FaPlus className="text-lg" />
                </button>
                {showDropdown && (
                  <div className="bg-white shadow-lg rounded-lg p-4 mt-4 w-full max-w-[720px]">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearch}
                      placeholder="Search tools..."
                      className="mb-4 px-2 py-1 border border-gray-300 rounded w-full"
                    />
                    {searching ? (
                      <p>Searching...</p>
                    ) : searchResults.length > 0 ? (
                      searchResults.map((tool) => (
                        <div
                          key={tool._id}
                          className="flex items-center mb-2 cursor-pointer"
                          onClick={() => handleToolSelect(tool._id)}
                        >
                          <img
                            src={tool.Image} // Using the direct URL from MongoDB
                            alt={tool.Name}
                            className="w-10 h-10 mr-2"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/images/defaultprofpic1.jpg";
                            }}
                          />
                          <span className="text-black">{tool.Name}</span>
                        </div>
                      ))
                    ) : (
                      aiTools.map((tool) => (
                        <div
                          key={tool._id}
                          className="flex items-center mb-2 cursor-pointer"
                          onClick={() => handleToolSelect(tool._id)}
                        >
                          <img
                            src={tool.Image} // Using the direct URL from MongoDB
                            alt={tool.Name}
                            className="w-10 h-10 mr-2"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/images/defaultprofpic1.jpg";
                            }}
                          />
                          <span className="text-black">{tool.Name}</span>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );

      case "interactions":
        return (
          <div className="ml-4 md:ml-6">
            {/* Interactions content goes here */}
          </div>
        );
      default:
        return null;
    }
  };
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  return (
    <div className="relative min-h-screen flex pt-0 md:pt-0 bg-white">
      {/* Sidebar for Desktop */}
      <div className="fixed bottom-4 mb-16 right-4 md:bottom-6 md:right-6 z-50">
        <FeedbackForm userId={user?._id} />
      </div>
      <div className="w-[220px] bg-slate-300 hidden md:flex flex-col justify-between"></div>

      {/* Main Content */}
      <div className={`pt-0 flex-1 pr-4`}>
        <div className="bg-white p-4 mt-1">
          {/* Container for profile picture */}
          <div
            className="relative bg-gray-300 shadow-lg w-full flex items-start md:items-center rounded-l-full rounded-tr-3xl"
            style={{
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            {/* Profile Picture */}
            <div className="rounded-full overflow-hidden h-24 w-24 md:h-32 md:w-32 flex justify-center items-center bg-slate-500">
              <img
                src={user?.profilePic || "/images/finalplaceholder.png"}
                alt="Profile"
                className="h-full w-full object-cover"
                onClick={() => profilePicInputRef.current?.click()}
              />{" "}
              <input
                type="file"
                id="profilePicInput"
                ref={profilePicInputRef}
                style={{ display: "none" }}
                onChange={handleProfilePicUpload}
              />
            </div>
            <FaPen
              className="absolute bottom-2 right-2 text-lg cursor-pointer text-gray-600"
              onClick={handleEditClick}
            />
          </div>

          {/* Name and Bio Container */}
          <div
            className="mt-4 flex items-center justify-between bg-white"
            style={{ borderBottom: "none" }}
          >
            <div className="flex-1 bg-white shadow-md rounded-lg px-6 py-4 inline-block">
              <h2 className="md:text-2xl font-poppins text-black">
                {user?.name || "Loading..."}
              </h2>
              <p className="text-black font-poppins">
                {user?.bio || "No bio available."}
              </p>
            </div>

            {/* Feedback Button */}
          </div>

          {showFeedbackForm && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <FeedbackForm userId={user._id} onClose={toggleFeedbackForm} />
              </div>
            </div>
          )}

          {/* Followers and Following section */}
          <div className="mt-4 flex justify-start items-center space-x-4">
            <div
              className="bg-gray-300 shadow-md rounded-lg px-4 py-2 cursor-pointer"
              onClick={() => toggleSection("followers")}
            >
              <p className="text-black text-center">
                <span className="font-bold">
                  {user?.followers?.length || 0}
                </span>{" "}
                Followers
              </p>
            </div>
            <div
              className="bg-gray-300 shadow-md rounded-lg px-4 py-2 cursor-pointer"
              onClick={() => toggleSection("following")}
            >
              <p className="text-black text-center">
                <span className="font-bold">
                  {user?.following?.length || 0}
                </span>{" "}
                Following
              </p>
            </div>
          </div>

          {/* Conditionally render the followers/following list underneath */}
          {activeSection && (
            <div className="mt-2 bg-white rounded-lg shadow-md p-4 max-h-60 overflow-y-auto">
              <h2 className="text-lg font-bold text-black mb-4">
                {activeSection === "followers" ? "Followers" : "Following"}
              </h2>
              {userList.length > 0 ? (
                userList.map((uploader) => (
                  <div
                    key={uploader._id}
                    className="flex items-center space-x-4 cursor-pointer mb-2"
                  >
                    <img
                      src={uploader.profilePic || "/images/defaultprofpic1.jpg"}
                      alt={uploader.name}
                      className="w-12 h-12 rounded-full object-cover cursor-pointer"
                      onClick={() => navigateToUploaderProfile(uploader._id)} // Navigate on click
                    />

                    <span className="text-black">{uploader.name}</span>
                  </div>
                ))
              ) : (
                <p className="text-black font-poppins">No users found.</p>
              )}
            </div>
          )}

          {/* Edit Popup */}
          {showEditPopup && (
            <div className="fixed w-screen p-4 md:p-0 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl text-black font-poppins text-center mb-4">
                  Edit Info
                </h2>
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-4">
                    <label className="block text-black text-xl font-poppins">
                      Name:
                    </label>
                    <input
                      type="text"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      className="p-2 border bg-slate-200 text-black border-gray-300 rounded w-full"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-black font-poppins text-xl">
                      Bio:
                    </label>
                    <textarea
                      value={newBio}
                      onChange={(e) => setNewBio(e.target.value)}
                      className="p-2 border bg-slate-200 text-black border-gray-300 rounded w-full"
                    ></textarea>
                  </div>
                  <div className="flex justify-around">
                    <button
                      type="button"
                      onClick={() => setShowEditPopup(false)}
                      className="bg-red-500 text-gray-800 px-4 py-2 rounded-full"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-full"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className={showEditPopup ? "filter blur-sm" : ""}>
            {/* All your background content goes here */}
          </div>
        </div>

        {/* Tabs Section */}
        <div className="mt-4 flex justify-between border-b">
          <button
            className={`flex-1 px-1 py-2 font-poppins md:text-xl text-black text-center ${
              activeTab === "namekit"
                ? "rounded-full bg-gray-500 text-white"
                : ""
            }`}
            onClick={() => setActiveTab("namekit")}
          >
            My Toolkit
          </button>
          <button
            className={`flex-1 px-1 py-2 font-poppins md:text-xl text-black text-center ${
              activeTab === "posts" ? "rounded-full bg-gray-500 text-white" : ""
            }`}
            onClick={() => setActiveTab("posts")}
          >
            Posts
          </button>
          <button
            className={`flex-1 px-1 py-2 font-poppins  md:text-xl text-black text-center ${
              activeTab === "interactions"
                ? "rounded-full bg-gray-500 text-white"
                : ""
            }`}
            onClick={() => setActiveTab("interactions")}
          >
            Interactions
          </button>
        </div>

        {renderContent()}
      </div>
      <div className="hidden md:flex flex-col justify-start items-center bg-slate-300 p-4 rounded-lg w-60 h-auto mt-2 ml-4">
        <div className="flex flex-row items-center justify-between w-full relative">
          <Link to="/home">
            <span
              className="material-icons text-black"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              home
            </span>
          </Link>

          <Link to="/profile">
            <span
              className="material-icons text-black"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              person
            </span>
          </Link>

          <div className="relative">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={handleNotificationClick}
            >
              notifications
            </span>
            {notificationCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-4px",
                  right: "60%",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "14px",
                }}
              >
                {notificationCount}
              </span>
            )}
            {showNotificationPopup && (
              <NotificationPopup
                userId={user._id}
                onClose={() => setShowNotificationPopup(false)}
                position="down"
              />
            )}
          </div>

          <div className="relative">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={toggleSettings}
            >
              settings
            </span>
            {settingsOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                <button
                  onClick={() => setShowResetPasswordPopup(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => setShowLogoutPopup(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Sticky Header for Mobile View */}
      <div className="fixed bottom-0 left-0 w-screen right-0 bg-slate-200 p-4 flex justify-between items-center md:hidden z-50">
        <Link to="/home" className="flex-1 text-center">
          <span
            className="material-icons text-black"
            style={{
              cursor: "pointer",
              fontSize: "32px",
            }}
          >
            home
          </span>
        </Link>

        <Link to="/profile" className="flex-1 text-center">
          <span
            className="material-icons text-black"
            style={{
              cursor: "pointer",
              fontSize: "32px",
            }}
          >
            person
          </span>
        </Link>

        <div className="relative flex-1 text-center">
          <span
            className="material-icons text-black cursor-pointer"
            style={{ fontSize: "32px" }}
            onClick={handleNotificationClick}
          >
            notifications
          </span>
          {notificationCount > 0 && (
            <span
              style={{
                position: "absolute",
                top: "-4px",
                right: "20%",
                background: "red",
                color: "white",
                borderRadius: "50%",
                padding: "2px 6px",
                fontSize: "14px",
              }}
            >
              {notificationCount}
            </span>
          )}
          {/* Conditionally render the NotificationPopup */}
          {showNotificationPopup && (
            <NotificationPopup
              userId={user._id}
              onClose={() => setShowNotificationPopup(false)}
              position="up"
            />
          )}
        </div>

        <div className="relative flex-1 text-center">
          <span
            className="material-icons text-black cursor-pointer"
            style={{ fontSize: "32px" }}
            onClick={toggleSettings}
          >
            settings
          </span>

          {settingsOpen && (
            <div className="absolute right-0 bottom-12 w-48 bg-white rounded-md shadow-lg z-50">
              <button
                onClick={() => setShowResetPasswordPopup(true)}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Reset Password
              </button>
              <button
                onClick={() => setShowLogoutPopup(true)}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Log Out
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold font-poppins text-black mb-4">
              Are you sure?
            </h2>
            <p className="mb-6">Do you want to log out?</p>
            <div className="flex justify-around">
              <button
                onClick={() => setShowLogoutPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                No
              </button>
              <button
                onClick={handleLogout}
                className="bg-red-500 text-black px-4 py-2 rounded-full"
              >
                Yes, Log Out
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reset Password Popup */}
      {showResetPasswordPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold text-black mb-4">
              Reset Password
            </h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Current Password
              </label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-bold mb-2">
                New Password
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="flex justify-around">
              <button
                onClick={() => setShowResetPasswordPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                Cancel
              </button>
              <button
                onClick={handleResetPassword}
                className="bg-blue-500 text-black px-4 py-2 rounded-full"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
