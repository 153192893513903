import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ShimmerButton from "../components/ShimmerButton";
import { NeonGradientCard } from "../components/NeonGradientCard";
import { ToastContainer, toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Error state for showing invalid credentials message
  const [submitted, setSubmitted] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false); // State for forgot password popup
  const [forgotEmail, setForgotEmail] = useState(""); // Email for forgot password

  const navigate = useNavigate();

  const showSuccessToast = (message) => {
    toast.success(message, {
      autoClose: 2000, // Toast closes after 2 seconds
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "successToast",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { email, password };

    try {
      const response = await fetch("https://themindlr.com/api/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include",
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.message === "Sign in successful") {
          // Display success toast
          //showSuccessToast("Sign in successful!");

          // Navigate to home after a delay
          //setTimeout(() => {
          navigate("/home");
          //}, 2000); // Wait for 2 seconds to allow toast to be visible
        }
      } else if (response.status === 400) {
        setErrorMessage("Invalid credentials. Please try again.");
      } else {
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error signing in", error);
      setErrorMessage(
        "An error occurred while signing in. Please try again later."
      );
    }
  };

  // Forgot password function
  const handleForgotPasswordSubmit = async () => {
    toast.success("New password sent to your email!", {
      autoClose: 2000, // Toast closes after 2 seconds
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "successToast",
    });
    if (!forgotEmail) {
      setErrorMessage("Please enter your email");
      return;
    }

    try {
      const response = await fetch(
        "https://themindlr.com/api/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: forgotEmail }),
        }
      );

      if (!response.ok) {
        setErrorMessage("Failed to send password. Please try again.");
      } else {
        setShowForgotPasswordPopup(false); // Close popup on success
      }
    } catch (error) {
      console.error("Error sending forgot password email", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex justify-center items-center mt-12 mb-12 px-4 relative">
      {!submitted ? (
        <NeonGradientCard className="w-full max-w-md p-0 bg-black">
          <form onSubmit={handleSubmit} className="px-6 py-8">
            <h2 className="text-4xl font-poppins mb-4 text-center text-black dark:text-white">
              Sign In
            </h2>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
              {/* Error message displayed below password input */}
              {errorMessage && (
                <div className="bg-red-100 text-red-600 border border-red-500 rounded-md mt-2 p-2">
                  <span className="block font-semibold text-md">
                    Invalid credentials
                  </span>
                  {errorMessage}
                </div>
              )}
            </div>
            <div className="text-center">
              <button
                type="button"
                className="text-blue-500 underline"
                onClick={() => setShowForgotPasswordPopup(true)}
              >
                Forgot password?
              </button>
            </div>
            <div className="flex justify-center mt-4">
              <ShimmerButton className="font-poppins mx-auto md:mx-0">
                Sign In
              </ShimmerButton>
            </div>
          </form>
        </NeonGradientCard>
      ) : (
        <div className="text-center text-4xl font-poppins text-white dark:text-white p-4 rounded-md">
          <h4>Thank you, you have been added to the waitlist!</h4>
        </div>
      )}

      {/* Forgot Password Popup */}
      {showForgotPasswordPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gray-500 p-6 rounded-lg shadow-lg w-11/12 max-w-md mx-auto">
            <h2 className="text-xl font-poppins mb-4">Forgot Password ?</h2>
            <label className="block mb-2 font-poppins">Enter your email:</label>
            <input
              type="email"
              value={forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-400 rounded-md"
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={handleForgotPasswordSubmit}
            >
              Submit
            </button>
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 ml-2 rounded-md"
              onClick={() => setShowForgotPasswordPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Add the ToastContainer to display toasts */}
      <ToastContainer />
    </div>
  );
}
