import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Layout from "./components/Layout";
import SimpleLayout from "./components/SimpleLayout";
import ContactForm from "./components/ContactForm";
import WelcomePage from "./pages/WelcomePage";
import Blog from "./pages/Blog";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import UploaderProfile from "./pages/UploaderProfile";
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute component
import AlphaDetailsForm from "./components/AlphaDetailsForm";
import AlphaDetails from "./pages/AlphaDetails";
import AlphaInputs from "./pages/AlphaInputs";
import AlphaRelevance from "./pages/AlphaRelevance";
import ToolMakerWelcome from "./pages/ToolMakerWelcome";
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Routes using the Standard Layout with Header and Footer */}
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/sign-in"
          element={
            <Layout>
              <SignIn />
            </Layout>
          }
        />
        <Route
          path="/register"
          element={
            <Layout>
              <Register />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/join-beta"
          element={
            <Layout>
              <ContactForm />
            </Layout>
          }
        />
        <Route
          path="/blog"
          element={
            <Layout>
              <Blog />
            </Layout>
          }
        />
        <Route
          path="/blog1"
          element={
            <Layout>
              <Blog1 />
            </Layout>
          }
        />
        <Route
          path="/blog2"
          element={
            <Layout>
              <Blog2 />
            </Layout>
          }
        />

        {/* Routes using Simple Layout (without Header and Footer) */}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <SimpleLayout>
                <WelcomePage />
              </SimpleLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <SimpleLayout>
                <Profile />
              </SimpleLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/uploader-profile/:uploaderId"
          element={
            <PrivateRoute>
              <SimpleLayout>
                <UploaderProfile />
              </SimpleLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/welcome"
          element={
            <SimpleLayout>
              <AlphaDetailsForm />
            </SimpleLayout>
          }
        />
        <Route
          path="/alpha-form-details"
          element={
            <SimpleLayout>
              <AlphaDetails />
            </SimpleLayout>
          }
        />
        <Route
          path="/alpha-user-feedback"
          element={
            <SimpleLayout>
              <AlphaInputs />
            </SimpleLayout>
          }
        />
        <Route
          path="/alpha-video-relevantornot"
          element={
            <SimpleLayout>
              <AlphaRelevance />
            </SimpleLayout>
          }
        />
        <Route
          path="/toolmaker-welcome"
          element={
            <SimpleLayout>
              <ToolMakerWelcome />
            </SimpleLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
